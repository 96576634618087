import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, PRIMARY_OUTLET, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    let pageTitlePrefix: string = environment.pageTitlePrefix;
    let route: ActivatedRouteSnapshot | undefined = snapshot.root;
    while (route !== undefined) {
      if (typeof route.data['pageTitlePrefixOverride'] === 'string') {
        pageTitlePrefix = route.data['pageTitlePrefixOverride'];
      }
      route = route.children.find((child) => child.outlet === PRIMARY_OUTLET);
    }

    const title = this.buildTitle(snapshot);
    if (title !== undefined) {
      this.title.setTitle(`${pageTitlePrefix === '' ? '' : pageTitlePrefix + ' | '}${title}`);
    } else {
      this.title.setTitle(`${pageTitlePrefix}`);
    }
  }
}
