import { MY_MEMBERSHIP_ERROR_MESSAGES } from '@config/control-errors/edit-employment-error-messages';
import {
  FfNgxControlErrorMessages,
  FF_NGX_DATE_ERROR_MESSAGES,
  FF_NGX_FILE_UPLOAD_ERROR_MESSAGES,
} from '@fagforbundet/ngx-components';

export const controlErrorMessages = {
  ...FF_NGX_FILE_UPLOAD_ERROR_MESSAGES,
  ...FF_NGX_DATE_ERROR_MESSAGES,
  ...MY_MEMBERSHIP_ERROR_MESSAGES,
  unknown: ({ where }) => `Det skjedde en uventet feil ${where}`,
} satisfies FfNgxControlErrorMessages;
