import { Routes } from '@angular/router';
import { ffNgxAuthCanMatchGuard } from '@fagforbundet/ngx-components';

export type AvailableSubDomains = 'minside' | 'utmelding';

const domainRoutes: { [key in AvailableSubDomains]: Routes } = {
  minside: [
    {
      path: '',
      loadChildren: () => import('@modules/public/public.routes'),
    },
    {
      path: '',
      loadChildren: () => import('@modules/authenticated/authenticated.routes'),
      canMatch: [ffNgxAuthCanMatchGuard],
    },
  ],
  utmelding: [
    {
      path: '',
      loadChildren: () => import('@modules/membership-cancellation-landing-page/membership-cancellation-landing-page.routes'),
    },
  ],
};

export function getRoutesForSubDomain(subDomain?: AvailableSubDomains): Routes {
  const s = subDomain || window.location.hostname.substring(
    0,
    window.location.hostname.lastIndexOf('.'),
  ).replace('.fagforbundet', '');

  return domainRoutes[s as AvailableSubDomains];
}

export const routes = getRoutesForSubDomain();
